import React, { useState } from "react"
import { graphql } from "gatsby"
import Seo from "src/components/SEO"
import ResourcesFilters from "src/components/ResourcesFilters"
import ResourcesHeader from "src/components/ResourcesHeader"
import ResourcesListHeader from "src/components/ResourcesListHeader"
import ResourceTile from "src/components/ResourceTile"
import {
  Wrapper,
  Container,
  Sidebar,
  SearchForm,
  Main,
  ResourceList,
  ResourceListItems,
} from "src/templates/resource-category"
import { slugify } from "src/utils/helpers"
import { get } from "lodash"

export const pageQuery = graphql`
  query ($id: String) {
    allStrapiResource(
      sort: { fields: createdAt, order: DESC }
      filter: { author: { id: { eq: $id } } }
    ) {
      edges {
        node {
          Title
          category {
            id
          }
          country {
            name
          }
          date_of_capture
          content_type
          number_of_files
          createdAt(formatString: "MMMM D, YYYY")
          Preview {
            url
            localFile {
              childImageSharp {
                gatsbyImageData(height: 143, quality: 80, layout: FIXED)
              }
            }
          }
        }
      }
    }
  }
`

const ResourceAuthor = (props) => {
  const { name, allCategories } = props.pageContext

  const [filter, setFilter] = useState({
    typeVideos: true,
    typePhotos: true,
    country: "",
    year: "",
  })

  const [sort, setSort] = useState("Date added – newest first")

  const categoryResources = props.data.allStrapiResource.edges.map(
    (r) => r.node
  )

  const resources = categoryResources.filter((r) => {
    let result = true
    if (filter.year && !r.date_of_capture) {
      return false;
    }
    if (
      (!filter.typeVideos && r.content_type === "Video") ||
      (!filter.typePhotos && r.content_type === "Picture") ||
      (filter.country && (!r.country || r.country.name !== filter.country)) ||
      (filter.year && r.date_of_capture && r.date_of_capture.substring(0, 4) != filter.year)
    ) {
      result = false
    }
    return result
  })

  if (sort === "Date added – oldest first") {
    resources.reverse()
  } else if (sort === "Name – A-Z") {
    resources.sort((a, b) => a.Title.localeCompare(b.Title))
  } else if (sort === "Name – Z-A") {
    resources.sort((a, b) => b.Title.localeCompare(a.Title))
  }

  const rootPath = "/resources"
  const currentPath = `${rootPath}/authors/${slugify(name)}`

  const breadcrumbs = [
    {
      name: "Resource library",
      path: `${rootPath}#categories`,
    },
    {
      name: `Author: ${name}`,
      path: currentPath,
    },
  ]

  const showTypeFilter = categoryResources.find((r) =>
    ["Video", "Picture"].includes(r.content_type)
  )

  function getCountries() {
    let countries = []
    categoryResources.forEach((r) => {
      if (r.country && !countries.includes(r.country.name)) {
        countries.push(r.country.name)
      }
    })
    countries.sort()
    return countries
  }

  function getYears() {
    let years = []
    categoryResources.forEach((r) => {
      if (r.date_of_capture && !years.includes(r.date_of_capture.substring(0, 4))) {
        years.push(r.date_of_capture.substring(0, 4))
      }
    })
    years.sort().reverse()
    return years
  }

  function updateFilter(filterName, event) {
    const newFilter = { ...filter }
    if (filterName === "typeVideos") {
      newFilter.typeVideos = event.target.checked
    } else if (filterName === "typePhotos") {
      newFilter.typePhotos = event.target.checked
    } else if (filterName === "country") {
      newFilter.country = event.target.value ? event.target.value : ""
    } else if (filterName === "year") {
      newFilter.year = event.target.value ? parseInt(event.target.value) : ""
    }
    setFilter(newFilter)
  }

  function getResourcePath(categoryId, title) {
    if (!categoryId) {
      return rootPath
    }

    const categories = []

    function getCat(id) {
      allCategories.forEach((category) => {
        const isCat = category.strapiId === id
        if (!isCat) {
          return
        }
        categories.push(slugify(category.name))
        if (category.parent_category) {
          getCat(category.parent_category.id)
        }
      })
    }

    getCat(categoryId)

    const path = `${rootPath}/${categories
      .reverse()
      .slice(0, 1)
      .join("/")}/${slugify(title)}`

    return path
  }

  function onSortChange(name, value) {
    setSort(value)
  }

  return (
    <Wrapper>
      <Seo title={`${name}’s resources`} />
      <Container wide>
        <Sidebar>
          <SearchForm />
          {categoryResources.length > 0 && (
            <ResourcesFilters
              showTypeFilter={showTypeFilter}
              countryList={getCountries()}
              yearList={getYears()}
              onUpdateFilter={updateFilter}
            />
          )}
        </Sidebar>
        <Main>
          <ResourcesHeader
            title={name}
            titlePrefix="Author:"
            breadcrumbs={breadcrumbs}
          />
          <ResourceList>
            <ResourcesListHeader
              resources={resources}
              onSortChange={onSortChange}
            />
            <ResourceListItems>
              {resources.map(
                (
                  {
                    Title,
                    category,
                    country,
                    date_of_capture,
                    content_type,
                    number_of_files,
                    createdAt,
                    Preview,
                  },
                  index
                ) => {
                  return (
                    <ResourceTile
                      path={getResourcePath(category && category.id, Title)}
                      title={Title}
                      type={content_type}
                      numberOfFiles={number_of_files}
                      country={country && country.name}
                      year={date_of_capture ? date_of_capture.substring(0, 4) : ""}
                      dateAdded={createdAt}
                      imageSharp={get(Preview[0], "localFile.childImageSharp")}
                      imageSharpSequence={
                        Preview.length > 0 &&
                        Preview.slice(0, 10).map((p) =>
                          get(p, "localFile.childImageSharp")
                        )
                      }
                      key={index}
                    />
                  )
                }
              )}
            </ResourceListItems>
          </ResourceList>
        </Main>
      </Container>
    </Wrapper>
  )
}

export default ResourceAuthor
